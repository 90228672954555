import { UseToastOptions } from '@chakra-ui/react';
import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

// ref: https://github.com/m2x-software/m2x-monorepo/pull/373
export const auth = initializeAuth(app, {
  popupRedirectResolver: undefined,
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
});

export const getTokenForPushNotifications = async () => {
  const messaging = getMessaging();
  const token = await getToken(messaging, {
    vapidKey: import.meta.env.VITE_VAPID_KEY,
  });

  return token;
};

export const deleteTokenForPushNotifications = () => {
  const messaging = getMessaging();
  deleteToken(messaging).catch((err) => {
    console.warn('Error deleting token: ', err);
  });
};

export const setOnMessage = (toast: (options: UseToastOptions) => void) => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    toast({
      position: 'top-right',
      title: payload.data?.title || 'メッセージが届いております',
      description: payload.data?.body,
      id: payload.data?.messageId,
      status: 'warning',
      containerStyle: { maxW: '380px' },
    });
  });
};
